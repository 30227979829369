// React Hot Loader needs to be imported before everything
import 'src/js/config/wdyr'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { QueryClientProvider } from 'react-query'
import { HistoryRouter } from 'redux-first-history/rr6'
import { checkLoginRedirection } from 'helpers/redirects'

import i18n from './js/services/i18n' // eslint-disable-line

import App from './js/containers/app_container'

import store, { history } from './js/store'

import { initSentry } from './js/config/sentry'
import { initDatadog } from './js/config/datadog'
import { queryClient } from './js/services/query_client'

import './css/main.sass'
import { ErrorBoundary } from './js/components/common/ErrorBoundary'

const willRedirect = checkLoginRedirection()

const init = () => {
  initSentry()
  initDatadog()

  // Exposing store to Cypress so we can easily
  // dispatch actions to skip steps and make
  // tests faster. We can also use this to get
  // state data and reuse them, so we don't need
  // to hardcode it
  if (window.Cypress) {
    window.__store__ = store
  }

  ReactDOM.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HistoryRouter history={history}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </HistoryRouter>
      </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
  )
}

if (!willRedirect) {
  init()
}
