import { getSelectedAmazonSellerAccount } from 'helpers/amazon_seller_account'
import { setSentryMWSData } from 'src/js/config/sentry'
import Api from 'services/api'
import { MARKETPLACES_REGIONS } from 'constants/countries'
import {
  getPathData,
  sendSegmentTrackEvent,
  safeSegmentCall
} from 'services/segment'
import { getDefaultMarketplaceForSellerId } from 'helpers/account'
import { getPathnameFromFullHashUrl } from 'helpers/url'
import { PRODUCT_FOR_SEGMENT } from 'helpers/segment'
import { persistGlobalData } from 'helpers/storage'
import { SET_AMAZON_SELLER_ACCOUNTS_DATA } from 'constants/action_types/amazon_seller_accounts'
import * as ACCOUNT_ACTIONS from '../../constants/action_types/accounts/amazonSellerAccounts'

import { createNotification } from '../notifications'

export const updateAmazonSellerAccountDetails = updatedAccount => async (
  dispatch,
  getState
) => {
  dispatch({
    type:
      ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_PATCH_DETAILS_PENDING_STATUS
  })

  const res = await Api.patchAmazonSellerAccountDetails(updatedAccount)

  if (res.ok) {
    dispatch({
      type:
        ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_PATCH_DETAILS_COMPLETE,
      payload: res.data.amazon_seller_account
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Failed to update amazon seller account'
      })
    )
    return res.error
  }

  dispatch({
    type:
      ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_PATCH_DETAILS_COMPLETE_STATUS
  })

  const state = getState()
  persistGlobalData(state)

  const {
    amazon_seller_account_id,
    marketplace_id
  } = getSelectedAmazonSellerAccount(state)
  setSentryMWSData({ amazon_seller_account_id, marketplace_id })
}

export const deleteAmazonSellerAccount = account => async (
  dispatch,
  getState
) => {
  const { id, has_ppc_access, merchant_id, region, sc_email, label } = account

  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_DESTROY_PENDING_STATUS,
    payload: {
      accountId: id
    }
  })

  const res = await Api.deleteAmazonSellerAccount(id)

  if (res.ok) {
    dispatch({
      type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_DESTROY_COMPLETE,
      payload: { accountId: id }
    })

    dispatch(
      createNotification({
        message:
          'We have started deleting your seller account. This can take a few minutes.',
        level: 'success',
        title: 'Delete Started'
      })
    )

    safeSegmentCall(() => {
      const { amazonSellerAccounts } = getState().amazonSellerAccounts
      sendSegmentTrackEvent('Amazon Account Removed', {
        region: MARKETPLACES_REGIONS[region?.toLowerCase()],
        marketplace: getDefaultMarketplaceForSellerId(
          amazonSellerAccounts,
          merchant_id
        )?.name,
        hasPpcAccess: has_ppc_access,
        accountNickname: label,
        sellerId: merchant_id,
        sellerCentralEmail: sc_email,
        product: PRODUCT_FOR_SEGMENT,
        tab: getPathData(getPathnameFromFullHashUrl(document.URL))?.name,
        accountType: has_ppc_access ? 'both' : 'mws'
      })
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Failed to delete amazon seller account'
      })
    )
    return res.error
  }

  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_DESTROY_COMPLETE_STATUS,
    payload: {
      accountId: id
    }
  })

  const state = getState()
  persistGlobalData(state)

  const {
    amazon_seller_account_id,
    marketplace_id
  } = getSelectedAmazonSellerAccount(state)
  setSentryMWSData({ amazon_seller_account_id, marketplace_id })
}

export const showAmazonSellerAccountModal = (
  accountId,
  modal
) => async dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_SHOW_MODAL,
    payload: {
      accountId,
      modal
    }
  })
}

export const closeAmazonSellerAccountModal = () => async dispatch => {
  dispatch({
    type: ACCOUNT_ACTIONS.ACCOUNT_AMAZON_SELLER_ACCOUNT_CLOSE_MODAL
  })
}

export const isSellerDataAvailable = () => async (dispatch, getState) => {
  const { isLoading, sellerDataAvailable } = getState().amazonSellerAccounts

  if (!isLoading && sellerDataAvailable) {
    return true
  }

  // get amazonSellerAccounts again to check availability
  try {
    const res = await Api.getAmazonSellerAccounts()

    if (!res.ok) {
      return false
    }

    dispatch({
      type: SET_AMAZON_SELLER_ACCOUNTS_DATA,
      payload: {
        amazonSellerAccounts: res.data,
        sellerDataAvailable: true
      }
    })

    return true
  } catch (e) {
    return false
  }
}
